<template>
  <a-drawer
    :destroy-on-close="true"
    :title="detail.id ? detail.name : '屏蔽策略创建'"
    :visible="visible"
    :width="1000"
    @close="closeDrawer"
  >
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :wrapperCol="{ span: 20 }"
    >
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="严重性" prop="severities">
        <a-select
          v-model="form.severities"
          mode="multiple"
          :options="options.severity"
        ></a-select>
      </a-form-model-item>
      <a-form-model-item label="生效周期">
        <a-radio-group v-model="form.daily" button-style="solid">
          <a-radio-button :value="false" style="padding: 0 32px">
            单次
          </a-radio-button>
          <a-radio-button :value="true" style="padding: 0 32px">
            每日
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="生效时间">
        <div v-if="form.daily" style="display: flex">
          <a-form-model-item
            prop="timeFrom"
            :rules="[
              {
                message: '请选择开始时间',
                required: true,
                trigger: 'change'
              }
            ]"
            style="width: 50%; margin-bottom: 0"
          >
            <a-time-picker
              v-model="form.timeFrom"
              format="HH:mm"
              placeholder="请选择开始时间"
              style="width: 100%"
            ></a-time-picker>
          </a-form-model-item>
          <div style="margin: 0 8px">~</div>
          <a-form-model-item
            prop="timeTo"
            :rules="[
              {
                message: '请选择结束时间',
                required: true,
                trigger: 'change'
              }
            ]"
            style="margin-bottom: 0; width: 50%"
          >
            <a-time-picker
              v-model="form.timeTo"
              format="HH:mm"
              placeholder="请选择结束时间"
              style="width: 100%"
            ></a-time-picker>
          </a-form-model-item>
        </div>

        <div v-else style="display: flex">
          <a-form-model-item
            prop="datetimeFrom"
            :rules="[
              {
                message: '请选择开始时间',
                required: true,
                trigger: 'change'
              }
            ]"
            style="margin-bottom: 0; width: 50%"
          >
            <a-date-picker
              v-model="form.datetimeFrom"
              format="YYYY-MM-DD HH:mm"
              placeholder="请选择开始时间"
              :show-time="{
                format: 'HH:mm'
              }"
              style="width: 100%"
            ></a-date-picker>
          </a-form-model-item>
          <div style="margin: 0 8px">~</div>
          <a-form-model-item
            prop="datetimeTo"
            :rules="[
              {
                message: '请选择结束时间',
                required: true,
                trigger: 'change'
              }
            ]"
            style="margin-bottom: 0; width: 50%"
          >
            <a-date-picker
              v-model="form.datetimeTo"
              :disabled-date="disabledDate"
              format="YYYY-MM-DD HH:mm"
              placeholder="请选择结束时间"
              :show-time="{
                format: 'HH:mm'
              }"
              style="width: 100%"
            ></a-date-picker>
          </a-form-model-item>
        </div>
      </a-form-model-item>
      <a-form-model-item label="资源配置" prop="conditions">
        <div
          v-for="(condition, index) in form.conditions"
          :key="`condition_${index}`"
          style="display: flex"
        >
          <a-form-model-item
            :prop="`conditions[${index}].source_type`"
            :rules="[
              {
                message: '请选择资源类型',
                required: true,
                trigger: 'change'
              }
            ]"
            style="width: 160px; margin-right: 16px"
          >
            <a-select
              v-model="condition.source_type"
              :options="options.sourceType"
            ></a-select>
          </a-form-model-item>
          <a-form-model-item
            :prop="`conditions[${index}].source_target`"
            :rules="[
              {
                message: '请选择资源范围',
                required: true,
                trigger: 'change'
              }
            ]"
            style="width: 160px"
          >
            <a-select
              v-model="condition.source_target"
              :options="options.sourceTarget"
            ></a-select>
          </a-form-model-item>
          <template v-if="condition.source_type">
            <!-- 群组 -->
            <a-form-model-item
              v-if="condition.source_target === 'group'"
              :prop="`conditions[${index}].groups`"
              :rules="[
                {
                  message:
                    '请选择' +
                    $t(`source_type.${condition.source_type}`) +
                    '群组',
                  required: true,
                  trigger: 'change'
                }
              ]"
              style="margin-left: 16px"
            >
              <group-select
                v-model="condition.groups"
                :source-type="condition.source_type"
                style="width: 400px"
              ></group-select>
            </a-form-model-item>
            <!-- 资源 -->
            <a-form-model-item
              v-else-if="condition.source_target === 'selected'"
              :rules="[
                {
                  message:
                    '请选择' + $t(`source_type.${condition.source_type}`),
                  required: true,
                  trigger: 'change'
                }
              ]"
              style="margin-left: 16px"
            >
              <multi-source-select
                v-model="condition.sources"
                :source-type="condition.source_type"
                style="width: 400px"
              ></multi-source-select>
            </a-form-model-item>
          </template>
          <a-form-model-item style="margin-left: 16px">
            <a-button
              icon="minus-circle"
              type="link"
              @click="form.conditions.splice(index, 1)"
            ></a-button>
          </a-form-model-item>
        </div>
        <a-button
          :block="true"
          icon="plus"
          type="dashed"
          @click="
            form.conditions.push({
              source_type: undefined,
              source_target: undefined,
              sources: undefined,
              groups: undefined
            })
          "
        >
          新增配置
        </a-button>
      </a-form-model-item>
      <a-form-model-item label="状态" prop="active">
        <a-switch v-model="form.active"></a-switch>
      </a-form-model-item>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button v-if="detail.id" type="primary" v-permission="{action: 'base.alert_block_strategy.update', effect: 'disabled'}" @click="ok">确定</a-button>
          <a-button v-else type="primary" @click="ok">确定</a-button>
          <a-button @click="closeDrawer">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import moment from 'moment'
import {
  getAlertBlockStrategy,
  updateAlertBlockStrategy,
  createAlertBlockStrategy
} from '@/api/alert-block-strategy'
import { alertSeverities, sourceTargets, sourceTypes } from '@/utils/const'
import GroupSelect from '@/components/select/GroupSelect'
import MultiSourceSelect from '@/components/select/MultiSourceSelect'

export default {
  name: 'InputDrawer',
  components: {
    GroupSelect,
    MultiSourceSelect
  },
  data () {
    return {
      visible: false,
      detail: {
        id: '',
        name: '',
        severities: [],
        datetimeRange: '',
        active: false,
        created_at: '',
        updated_at: '',
        conditions: []
      },
      form: {
        name: '',
        severities: undefined,
        daily: false,
        datetimeFrom: undefined,
        datetimeTo: undefined,
        timeFrom: undefined,
        timeTo: undefined,
        conditions: [{}],
        active: false
      },
      rules: {
        name: [
          {
            message: '请输入名称',
            required: true,
            trigger: 'blur'
          }
        ],
        severities: [
          {
            message: '请选择严重性',
            required: true,
            trigger: 'change'
          }
        ],
        conditions: [
          {
            message: '请添加资源配置',
            required: true,
            trigger: 'change'
          }
        ]
      },
      options: {
        severity: alertSeverities.map(value => {
          return {
            title: this.$t(`alert_severity.${value}`),
            value
          }
        }),
        sourceType: sourceTypes.map(value => {
          return {
            title: this.$t(`source_type.${value}`),
            value
          }
        }),
        sourceTarget: sourceTargets.map(value => {
          return {
            title: this.$t(`source_target.${value}`),
            value
          }
        })
      }
    }
  },
  methods: {
    show (id) {
      if (id) {
        // 编辑
        this.detail.id = id
        getAlertBlockStrategy(id)
          .then(res => {
            this.detail = res.data
            this.form.name = this.detail.name
            this.form.severities = this.detail.severities
            this.form.active = this.detail.active
            this.form.daily = this.detail.daily
            if (this.form.daily) {
              this.form.timeFrom = moment(this.detail.time_from, 'HH:mm')
              this.form.timeTo = moment(this.detail.time_to, 'HH:mm')
            } else {
              this.form.datetimeFrom = moment(
                this.detail.datetime_from,
                'YYYY-MM-DD HH:mm'
              )
              this.form.datetimeTo = moment(
                this.detail.datetime_to,
                'YYYY-MM-DD HH:mm'
              )
            }
            this.form.conditions = this.detail.conditions
          })
          .finally(() => {
            this.visible = true
          })
      } else {
        this.visible = true
      }
    },
    disabledDate (date) {
      return date && date <= moment().endOf('minute')
    },
    closeDrawer () {
      this.form = {
        name: '',
        severities: undefined,
        daily: false,
        datetimeFrom: undefined,
        datetimeTo: undefined,
        timeFrom: undefined,
        timeTo: undefined,
        conditions: [{}],
        active: false
      }
      this.detail = {
        id: '',
        name: '',
        severities: [],
        datetimeRange: '',
        active: false,
        created_at: '',
        updated_at: '',
        conditions: []
      }
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            name: this.form.name,
            severities: this.form.severities,
            daily: this.form.daily,
            active: this.form.active,
            conditions: this.form.conditions.map(condition => {
              const data = {
                source_type: condition.source_type,
                source_target: condition.source_target
              }
              if (condition.source_target === 'group') {
                data.group_ids = condition.groups.map(group => group.id)
              } else if (condition.source_target === 'selected') {
                data.source_ids = condition.sources.map(
                  source => source.key || source.id
                )
              }
              return data
            })
          }
          if (this.form.daily) {
            params.time_from = this.form.timeFrom.format('HH:mm')
            params.time_to = this.form.timeTo.format('HH:mm')
          } else {
            params.datetime_from =
              this.form.datetimeFrom.format('YYYY-MM-DD HH:mm')
            params.datetime_to = this.form.datetimeTo.format('YYYY-MM-DD HH:mm')
          }
          this.loading = true
          if (this.detail.id) {
            updateAlertBlockStrategy(this.detail.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('updated')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            createAlertBlockStrategy(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('updated')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    }
  }
}
</script>
